import { navigate } from 'gatsby-link'
import React from 'react'
import styled from 'styled-components'
import asyncLocalStorage from '../utils/asyncLocalStorage'
import { css } from 'styled-components'
import { isMobileView } from '../utils/isMobileView'

const Pill = styled.div`
  height: 32px;
  border-radius: 6px;
  padding: 2px 6px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.08);
  /* background-color: #f6f6f6; */
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  bottom: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  z-index: 999;
  @media (min-width: 769px) {
    top: 36px;
    right: 36px;
    bottom: auto;
    left: auto;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: auto;
    padding: auto;
  }
`

export const MiscText = styled.span`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  line-height: 28px;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: #afafaf;
`

export const MiscTextLink = styled.a`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  color: black;
  text-decoration: underline;
`

const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  /* text-transform: capitalize; */
  border: none;
  border-radius: 999px;
  background: black;
  cursor: pointer;
  padding: 2px 4px;
  &:hover {
    background: ${props => (props.disabled ? '' : '#2e2e2e')};
  }
  margin-left: 10px;
  ${props =>
    props.disabled
      ? css`
          background-color: #afafaf;
          /* color: #afafaf; */
          cursor: not-allowed;
        `
      : ''};
`

const AuthEmailPill = ({ email, redirectPath = '/', isVerified }) => {
  if (isMobileView()) {
    return null
  }
  return (
    <Pill>
      <MiscText>{isVerified ? 'Confirmed as' : 'Confirming as'}</MiscText>
      <MiscText style={{ color: 'black', paddingLeft: '7px', fontWeight: 500 }}>
        {email}
      </MiscText>
      <Button
        title="Log out and sign-in with a different email"
        // style={{ paddingLeft: '7px' }}
        // href="#"
        onClick={event => {
          event.preventDefault()
          asyncLocalStorage.removeItem('session').then(() => {
            navigate(redirectPath)
          })
        }}
      >
        Change
      </Button>
    </Pill>
  )
}

export default AuthEmailPill
