import { navigate } from 'gatsby-link'
import { get } from 'lodash'

const handleAuthNetworkError = error => {
  if (get(error, 'response.status') === 401) {
    setTimeout(() => {
      navigate('/signup')
    }, 3000)
  }
  return get(error, 'response.data.message', 'Something went wrong')
}

export default handleAuthNetworkError
