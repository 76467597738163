// Note: Do not destructure process.env
// See => https://www.gatsbyjs.org/docs/environment-variables/#example-of-using-an-environment-variable

const config = {
  backendUri: process.env.BACKEND_URI,
  pipeDriveUrl: process.env.PIPE_DRIVE_URL,
  scheduleEnterpriseDemoUrl: process.env.SCHEDULE_ENTERPRISE_DEMO_URL,
  stripePubKey: process.env.STRIPE_PUBLISHABLE_KEY,
}

export default config
