import React, { useState, useEffect, useMemo } from 'react'
import config from '../utils/config'
import Box from '../components/box'
import Loader from '../components/loader'
import asyncLocalStorage from '../utils/asyncLocalStorage'
import axios from 'axios'
import { navigate } from 'gatsby-link'
import handleAuthNetworkError from '../utils/handleAuthNetworkError'
import PulseLogo from '../icons/pulseLogo'
import AuthEmailPill from '../components/authEmailPill'
import { Link } from 'gatsby'
import styled from 'styled-components'
import debounce from '../utils/debounce'
import { emailRegex } from '../utils/regex'
import { useRef } from 'react'

const SIGNUP = '/signup'

export const SIGNUP_STEPS = {
  SIGNUP: SIGNUP,
  VERIFY: `${SIGNUP}/verify`,
  CONFLICT: `${SIGNUP}/conflict`,
  COMPLETE_PROFILE: `${SIGNUP}/complete-profile`,
  CLAIM_WORKSPACE: `${SIGNUP}/claim-workspace`,
  // SET_GOALS: `${SIGNUP}/set-goals`,
  SETUP_STREAMS: `${SIGNUP}/setup-streams`,
  // INVITE_PEOPLE: `${SIGNUP}/invite-people`,
  ONBOARDING_CALL: `${SIGNUP}/onboarding-call`,
  COMPLETE: `${SIGNUP}/complete`,
}

const LOGIN = '/login'

export const SIGNIN_STEPS = {
  LOGIN: LOGIN,
  VERIFY: `${LOGIN}/verify`,
  WORKSPACES: `${LOGIN}/workspaces`,
}

const LogoContainer = styled.div`
  position: absolute;
  margin-top: 56px;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 0px;
  @media (min-width: 769px) {
    margin-left: 80px;
    /* position: fixed; */
    justify-content: start;
    width: auto;
  }
`

const sessionSignUpStepRouteMap = new Map()
  .set('owner_info', SIGNUP_STEPS.COMPLETE_PROFILE)
  .set('workspace_domain', SIGNUP_STEPS.CLAIM_WORKSPACE)
  // .set('use_cases', SIGNUP_STEPS.SET_GOALS)
  .set('stream_selection', SIGNUP_STEPS.SETUP_STREAMS)
  .set('onboarding_call', SIGNUP_STEPS.ONBOARDING_CALL)
  // .set('invite_users', SIGNUP_STEPS.INVITE_PEOPLE)
  .set('provision_tenant', SIGNUP_STEPS.COMPLETE)

export const AuthContext = React.createContext(null)

export const AuthProvider = ({
  children,
  isSignIn = false,
  isNonAuthPage = false,
}) => {
  const [userSession, setUserSession] = useState(null)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)
  const [workspaces, setWorkspaces] = useState([])
  const isMounted = useRef(false)

  const checkEmailMxRecordWithDebounce = debounce(
    (value, res, rej, setWarning) => {
      if (!value || !emailRegex.test(value)) {
        setWarning(null)
        return
      }
      axios
        .post(`${config.backendUri}/auth/check-email-mx-record`, {
          email: value,
        })
        .then(result => setWarning(!result.data.isFound))
        .catch(() => {
          setWarning(false)
        })
    },
    500
  )

  const handleNavigate = route => {
    if (!isNonAuthPage) {
      if (window.location.pathname !== route) {
        navigate(route)
      }
    }
    setLoading(false)
  }

  const handleSignInSession = sessionResult => {
    if (sessionResult) {
      if (sessionResult.isVerified) {
        if (window.location.pathname !== SIGNIN_STEPS.WORKSPACES) {
          handleNavigate(SIGNIN_STEPS.WORKSPACES)
          return
        }
      } else {
        handleNavigate(SIGNIN_STEPS.VERIFY)
        return
      }
    } else {
      handleNavigate(SIGNIN_STEPS.LOGIN)
      return
    }
  }

  const handleSignUpSession = async (sessionResult, tokenCookie) => {
    if (sessionResult) {
      if (sessionResult.isVerified) {
        const isCompanyEmail = await axios.post(
          config.backendUri + '/auth/check-email',
          {
            email: sessionResult.email,
          },
          {
            headers: {
              Authorization: `Bearer ${tokenCookie}`,
            },
          }
        )
        if (!isCompanyEmail.data.isValid) {
          handleNavigate(SIGNIN_STEPS.LOGIN)
          return
        }
        const signupSessionStatus = await axios.get(
          config.backendUri + '/auth/sign-up/tenant/sign-up-status',
          {
            headers: {
              Authorization: `Bearer ${tokenCookie}`,
            },
          }
        )

        if (
          signupSessionStatus.data &&
          signupSessionStatus.data.workspaceExists
        ) {
          handleNavigate(SIGNIN_STEPS.WORKSPACES)
          return
        } else if (
          signupSessionStatus.data &&
          signupSessionStatus.data.creationInProgress
        ) {
          handleNavigate(SIGNUP_STEPS.CONFLICT)
          return
        } else if (
          window.location.pathname !==
          sessionSignUpStepRouteMap.get(sessionResult.signupStep)
        ) {
          handleNavigate(
            sessionSignUpStepRouteMap.get(sessionResult.signupStep)
          )
        }
      } else {
        handleNavigate(SIGNUP_STEPS.VERIFY)
        return
      }
    } else {
      handleNavigate(SIGNUP_STEPS.SIGNUP)
      return
    }
  }

  const handleSession = async () => {
    if (!isMounted.current) return null
    setLoading(true)
    const tokenCookie = await asyncLocalStorage.getItem('session')
    setToken(tokenCookie)
    if (tokenCookie) {
      try {
        const result = await axios.get(config.backendUri + '/auth/session', {
          headers: {
            Authorization: `Bearer ${tokenCookie}`,
          },
        })
        const sessionResult = result.data
        if (isSignIn) {
          handleSignInSession(sessionResult)
        } else {
          await handleSignUpSession(sessionResult, tokenCookie)
        }
        setUserSession(sessionResult)
        // set user workspaces
        if (sessionResult && sessionResult.isVerified) {
          const tenantResults = await axios.get(
            `${config.backendUri}/auth/session/tenants`,
            {
              headers: {
                Authorization: `Bearer ${tokenCookie}`,
              },
            }
          )
          setWorkspaces(tenantResults.data.tenantsData)
        }
        setLoading(false)
      } catch (error) {
        await asyncLocalStorage.removeItem('session')
        setLoading(false)
        if (
          window.location.pathname &&
          (window.location.pathname.startsWith('/login/') ||
            window.location.pathname.startsWith('/signup/'))
        ) {
          if (isSignIn) {
            navigate(SIGNIN_STEPS.LOGIN, {
              state: { error: handleAuthNetworkError(error) },
            })
          } else {
            navigate(SIGNUP_STEPS.SIGNUP, {
              state: { error: handleAuthNetworkError(error) },
            })
          }
        }
      }
    } else {
      setLoading(false)
      if (
        window.location.pathname &&
        (window.location.pathname.startsWith('/login/') ||
          window.location.pathname.startsWith('/signup/'))
      ) {
        if (isSignIn) {
          handleNavigate(SIGNIN_STEPS.LOGIN)
          return
        } else {
          handleNavigate(SIGNUP_STEPS.SIGNUP)
          return
        }
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    handleSession()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        userSession,
        signupStepsMap: sessionSignUpStepRouteMap,
        token,
        setLoading: (isItLoading = false) => {
          if (isMounted.current === true) {
            setLoading(isItLoading)
          }
        },
        checkEmailMxRecordWithDebounce,
        workspaces,
        navigateAuth: path => {
          if (isMounted.current && path) {
            navigate(path)
          }
        },
      }}
    >
      {userSession && !isNonAuthPage && (
        <AuthEmailPill
          email={userSession.email}
          isVerified={userSession.isVerified}
          redirectPath={isSignIn ? SIGNIN_STEPS.LOGIN : SIGNUP_STEPS.SIGNUP}
        />
      )}
      {/* {!isNonAuthPage && (
        <LogoContainer>
          <Link to="/">
            <PulseLogo fill="black" height="25" width="100" />
          </Link>
        </LogoContainer>
      )} */}
      {loading && (
        <Box
          height="100vh"
          width="100vw"
          display="flex"
          boxSizing="border-box"
          alignItems="center"
          justifyContent="center"
          position="fixed"
          zIndex="100"
          backgroundColor="white"
          top="0"
          bottom="0"
        >
          <Box fontSize="3rem">
            <Loader dark />
          </Box>
        </Box>
      )}
      {children}
    </AuthContext.Provider>
  )
}

export default {
  SignupContext: AuthContext,
  SignupProvider: AuthProvider,
  SIGNUP_STEPS,
}
